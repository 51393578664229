import * as types from '../types/AccountTypes';

export const updateAccount = (userId, params, currentUserInfo) => ({
    type: types.UPDATE_ACCOUNT,
    userId,
    params,
    currentUserInfo
});

export const onUpdateAccountSuccess = () => ({
    type: types.UPDATE_ACCOUNT_SUCCESS
});

export const updatePassword = (params) => ({
    type: types.UPDATE_PASSWORD,
    params
});

export const onUpdatePasswordSuccess = () => ({
    type: types.UPDATE_PASSWORD_SUCCESS
});

export const updatePhoto = (userId, params) => ({
    type: types.UPDATE_PHOTO,
    userId,
    params
});

export const onUpdatePhotoSuccess = () => ({
    type: types.UPDATE_PHOTO_SUCCESS
});

export const requestOtp = (userId, params) => ({
    type: types.REQUEST_OTP,
    userId,
    params
});

export const onRequestOtpSuccess = () => ({
    type: types.REQUEST_OTP_SUCCESS
});

export const verifyOtp = (userId, params) => ({
    type: types.VERIFY_OTP,
    userId,
    params
});

export const onVerifyOtpSuccess = () => ({
    type: types.VERIFY_OTP_SUCCESS
});

export const getTransactions = (userId, params) => ({
    type: types.GET_TRANSACTIONS,
    userId,
    params
});

export const onGetTransactionsSuccess = (payload) => ({
    type: types.GET_TRANSACTIONS_SUCCESS,
    payload
});

export const getMedicalHistory = (userId) => ({
    type: types.GET_MEDICAL_HISTORY,
    userId
});

export const onGetMedicalHistorySuccess = (payload) => ({
    type: types.GET_MEDICAL_HISTORY_SUCCESS,
    payload
});

export const updateMedicalHistory = (userId, params) => ({
    type: types.UPDATE_MEDICAL_HISTORY,
    userId,
    params
});

export const onUpdateMedicalHistorySuccess = () => ({
    type: types.UPDATE_MEDICAL_HISTORY_SUCCESS
});

export const getMembershipStatus = () => ({
    type: types.GET_MEMBERSHIP_STATUS
});

export const onGetMembershipStatusSuccess = (payload) => ({
    type: types.GET_MEMBERSHIP_STATUS_SUCCESS,
    payload
});
