import { call, put } from "redux-saga/effects";
import API from "../../network/apis/Auth";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as ACTIONS from "../actions/Auth";
import * as TYPES from "../types/AuthTypes";
import History from '../../routes/History';

export function* registerUser(action) {
    try {
        const response = yield call(API.registerUser, action.params);

        if (response.data.success) {
            yield put(ACTIONS.registerSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* loginUser(action) {
    try {
        const response = yield call(API.login, action.email, action.password, action.userType);
        
        if (response.data.success) {
            localStorage.setItem('token', response.data.data.token);
            yield put(ACTIONS.loginSuccess(response.data.data));
            History.push('/profile');
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getUserInfo() {
    try {
        const response = yield call(API.getUserInfo);
        yield put(ACTIONS.userInfoSuccess(response.data.data));
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getSubsInfo(action) {
    try {
        const response = yield call(API.getSubsInfo, action.phone);
        yield put(ACTIONS.onRequestSubInfoSuccess(response.data.data));
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getUserAccounts() {
    try {
        const response = yield call(API.getUserAccounts);
        yield put(ACTIONS.onRequestUserAccountsSuccess(response.data.data));
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* resetPasswordRequest(action) {
    try {
        const response = yield call(API.resetPasswordRequest, action.params);
    
        if (response.data.success) {
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* authSagas() {
    yield takeLatest(TYPES.AUTH_REGISTER_REQUEST, registerUser);
    yield takeLatest(TYPES.AUTH_LOGIN_REQUEST, loginUser);
    yield takeLatest(TYPES.AUTH_USER_INFO_REQUEST, getUserInfo);
    yield takeLatest(TYPES.AUTH_SUBS_INFO_REQUEST, getSubsInfo);
    yield takeLatest(TYPES.AUTH_USER_ACCOUNTS_REQUEST, getUserAccounts);
    yield takeLatest(TYPES.AUTH_RESET_PASSWORD_REQUEST, resetPasswordRequest);
}
