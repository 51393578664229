import Auth from '../../utils/Auth';
import { axiosInstance } from "./index";
const handlerEnabled = true;

const updateAccount = async (userId, params) => {
    return await axiosInstance.put(`api/users/${userId}`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const updatePassword = async (params) => {
    return await axiosInstance.post(`api/users/password`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const updatePhoto = async (userId, params) => {
    return await axiosInstance.post(`api/users/${userId}/photo`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const requestOtp = async (userId, params) => {
    return await axiosInstance.post(`api/requestotp/${userId}`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const verifyOtp = async (userId, params) => {
    return await axiosInstance.post(`api/verifyotp/${userId}`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getTransactions = async (userId, params) => {
    return await axiosInstance.get(`api/users/${userId}/transactions_date?&start=${params.startDate}&end=${params.endDate}`,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getMedicalHistory = async (userId) => {
    return await axiosInstance.get(`api/users/${userId}/patient`,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const updateMedicalHistory = async (userId, params) => {
    return await axiosInstance.put(`api/users/${userId}/patient`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getMembershipStatus = async () => {
    return await axiosInstance.get(`api/patient/member`,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

export default {
    updateAccount,
    updatePassword,
    updatePhoto,
    requestOtp,
    verifyOtp,
    getTransactions,
    getMedicalHistory,
    updateMedicalHistory,
    getMembershipStatus
};