export const GET_FEATURED_DOCTOR_REQUEST = 'GET_FEATURED_DOCTOR_REQUEST';
export const GET_FEATURED_DOCTOR_SUCCESS = 'GET_FEATURED_DOCTOR_SUCCESS';

export const GET_DOCTOR_SCHEDULE_REQUEST = 'GET_DOCTOR_SCHEDULE_REQUEST';
export const GET_DOCTOR_SCHEDULE_SUCCESS = 'GET_DOCTOR_SCHEDULE_SUCCESS';

export const GET_ALL_SPECIALTIES = 'GET_ALL_SPECIALTIES';
export const GET_ALL_SPECIALTIES_SUCCESS = 'GET_ALL_SPECIALTIES_SUCCESS';

export const GET_DOCTORS_INFO = 'GET_DOCTORS_INFO';
export const GET_DOCTORS_INFO_SUCCESS = 'GET_DOCTORS_INFO_SUCCESS';

export const UPDATE_DOCTORS_INFO = 'UPDATE_DOCTORS_INFO';
export const UPDATE_DOCTORS_INFO_SUCCESS = 'UPDATE_DOCTORS_INFO_SUCCESS';

export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';

export const GET_DOCTORS_BY_SPECIALTY = 'GET_DOCTORS_BY_SPECIALTY';
export const GET_DOCTORS_BY_SPECIALTY_SUCCESS = 'GET_DOCTORS_BY_SPECIALTY_SUCCESS';

export const GET_MORE_DOCTORS_BY_SPECIALTY = 'GET_MORE_DOCTORS_BY_SPECIALTY';
export const GET_MORE_DOCTORS_BY_SPECIALTY_SUCCESS = 'GET_MORE_DOCTORS_BY_SPECIALTY_SUCCESS';

export const GET_DOCTOR_EARNINGS = 'GET_DOCTOR_EARNINGS';
export const GET_DOCTOR_EARNINGS_SUCCESS = 'GET_DOCTOR_EARNINGS_SUCCESS';

export const GET_DOCTOR_CREDITS = 'GET_DOCTOR_CREDITS';
export const GET_DOCTOR_CREDITS_SUCCESS = 'GET_DOCTOR_CREDITS_SUCCESS';

export const GET_DOCTOR_REVIEWS = 'GET_DOCTOR_REVIEWS';
export const GET_DOCTOR_REVIEWS_SUCCESS = 'GET_DOCTOR_REVIEWS_SUCCESS';

export const ADD_DOCTOR_REVIEW = 'ADD_DOCTOR_REVIEW';
export const ADD_DOCTOR_REVIEW_SUCCESS = 'ADD_DOCTOR_REVIEW_SUCCESS';