import React from "react";

export const Login = React.lazy(() => import('../containers/Login/Login'));
export const ForgotPassword = React.lazy(() => import('../containers/ForgotPassword/ForgotPassword'));
export const Main = React.lazy(() => import('../containers/Main/Main'));
export const Home = React.lazy(() => import('../containers/Home/Home'));
export const Find = React.lazy(() => import('../containers/Find/Find'));
export const Doctor = React.lazy(() => import('../containers/Doctor/Doctor'));
export const Payment = React.lazy(() => import('../containers/Payment/Payment'));
export const Appointments = React.lazy(() => import('../containers/Appointments/Appointments'));
export const AppointmentDetail = React.lazy(() => import('../containers/AppointmentDetail/AppointmentDetail'));
export const Profile = React.lazy(() => import('../containers/Profile/Profile'));
export const AccountInformation = React.lazy(() => import('../containers/AccountInformation/AccountInformation'));
export const ChangePassword = React.lazy(() => import('../containers/ChangePassword/ChangePassword'));
export const ContactInformation = React.lazy(() => import('../containers/ContactInformation/ContactInformation'));
export const PatientTransactions = React.lazy(() => import('../containers/PatientTransactions/PatientTransactions'));
export const DoctorProfile = React.lazy(() => import('../containers/DoctorProfile/DoctorProfile'));
export const Register = React.lazy(() => import('../containers/Register/Register'));
export const AppointmentVideo = React.lazy(() => import('../containers/AppointmentVideo/AppointmentVideo'));
export const DoctorSchedule = React.lazy(() => import('../containers/DoctorSchedule/DoctorSchedule'));
export const Specialties = React.lazy(() => import('../containers/Specialties/Specialties'));
export const Doctors = React.lazy(() => import('../containers/Doctors/Doctors'));
export const AboutUs = React.lazy(() => import('../containers/AboutUs/AboutUs'));
export const Membership = React.lazy(() => import('../containers/Membership/Membership'));
export const WhyUs = React.lazy(() => import('../containers/WhyUs/WhyUs'));
export const GetListed = React.lazy(() => import('../containers/GetListed/GetListed'));
export const ContactUs = React.lazy(() => import('../containers/ContactUs/ContactUs'));
export const DoctorEarnings = React.lazy(() => import('../containers/DoctorEarnings/DoctorEarnings'));
export const MedicalHistory = React.lazy(() => import('../containers/MedicalHistory/MedicalHistory'));
export const DoctorRating = React.lazy(() => import('../containers/DoctorRating/DoctorRating'));
export const AgreementPage = React.lazy(() => import('../containers/BusinessAgreement/BusinessAgreement'));
export const PrivacyPolicy = React.lazy(() => import('../containers/PrivacyPolicy/PrivacyPolicy'));
export const PatientTerms = React.lazy(() => import('../containers/PatientTerms/PatientTerms'));