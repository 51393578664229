import React, { useState, useEffect } from "react";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import rtl from "jss-rtl";
import { create } from "jss";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import { useSelector } from "react-redux";
import App from "./containers/App";
import ErasBoldITC from './assets/fonts/Eras-Bold-Italic.ttf';

function ThemeApp() {
	const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
	const lang = useSelector(state => state.lang);
	const [direction, setDirection] = useState(lang === "en" ? "ltr" : "rtl");
	useEffect(() => {
		setDirection(lang === "en" ? "ltr" : "rtl");
	}, [lang]);

	const ErasBoldItalic = {
		fontFamily: 'ErasBoldITC',
		fontStyle: 'normal',
		fontWeight: 300,
		src: `url(${ErasBoldITC})`
		// src: `
		// 	local('ErasBoldTTF'),
		// 	local('ErasBoldTTF-Bold'),
		// 	url(${ErasBoldTTF}) format('ttf')
		// `
	  }

	let theme = createMuiTheme({
		typography: {
			fontFamily: 'Poppins, ErasBoldTTF, sans-serif',
			bannerh1: {
				fontFamily: 'Roboto',
				fontWeight: 700,
				color: '#0759D1',
				zIndex: 2,
				textAlign: 'center'
			},
			h2: {
				fontFamily: '"ErasBoldITC"',
				fontWeight: 800,
				fontSize: "3.5rem",
				color: '#0759D1',
				lineHeight: 1.2,
				letterSpacing: "0.03333em",
				textAlign: 'center'

			},
		},
		direction: direction,
		palette: {
			primary: {
				main: "#3ab7fd"
			},
			secondary: {
				main: "#f98f10"
			}
		},
		overrides: {
			MuiCssBaseline: {
			  '@global': {
				'@font-face': [ErasBoldItalic]
			  }
			}
		  }
		
	}); 

	theme = responsiveFontSizes(theme);

	return (
		<StylesProvider jss={jss}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</StylesProvider>
	);
}

export default ThemeApp;
