import * as types from "../types/DoctorTypes";

const INITIAL_STATE = {
    featuredDoctorsList: [],
    timeslotsList: [],
    allSpecialtiesList: [],
    doctorsInfo: null,
    isUpdateDoctorInfoSuccess: false,
    doctor: [],
    isUpateScheduleSuccess: false,
    doctorsList: [],
    nextPageUrl: null,
    earnings: null,
    doctorCredits: null,
    doctorReview: null,
    isAddDoctorReviewSuccess: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_FEATURED_DOCTOR_REQUEST:
            return {
                ...state
            };
        case types.GET_FEATURED_DOCTOR_SUCCESS:
            return {
                ...state,
                featuredDoctorsList: action.payload
            };
        case types.GET_DOCTOR_SCHEDULE_REQUEST:
            return {
                ...state,
                doctorUserId: action.doctorUserId,
                date: action.date,
                timeslotsList: []
            };
        case types.GET_DOCTOR_SCHEDULE_SUCCESS:
            return {
                ...state,
                timeslotsList: action.payload
            }
        case types.GET_ALL_SPECIALTIES:
            return {
                ...state
            };
        case types.GET_ALL_SPECIALTIES_SUCCESS:
            return {
                ...state,
                allSpecialtiesList: action.payload
            }
        case types.GET_DOCTORS_INFO:
            return {
                ...state,
                userId: action.userId
            };
        case types.GET_DOCTORS_INFO_SUCCESS:
            return {
                ...state,
                doctorsInfo: action.payload
            }
        case types.UPDATE_DOCTORS_INFO:
            return {
                ...state,
                userId: action.userId,
                params: action.params,
                isUpdateDoctorInfoSuccess: false
            };
        case types.UPDATE_DOCTORS_INFO_SUCCESS:
            return {
                ...state,
                isUpdateDoctorInfoSuccess: true
            }
        case types.GET_SCHEDULE:
            return {
                ...state,
                userId: action.userId
            };
        case types.GET_SCHEDULE_SUCCESS:
            return {
                ...state,
                doctor: action.payload
            }
        case types.UPDATE_SCHEDULE:
            return {
                ...state,
                isUpateScheduleSuccess: false,
                userId: action.userId,
                params: action.params
            };
        case types.UPDATE_SCHEDULE_SUCCESS:
            return {
                ...state,
                isUpateScheduleSuccess: true
            }
        case types.GET_DOCTORS_BY_SPECIALTY:
            return {
                ...state,
                specialtyId: action.specialtyId
            };
        case types.GET_DOCTORS_BY_SPECIALTY_SUCCESS:
            return {
                ...state,
                doctorsList: action.payload.data,
                nextPageUrl: action.payload.next_page_url
            }
        case types.GET_MORE_DOCTORS_BY_SPECIALTY:
            return {
                ...state,
                url: action.url
            };
        case types.GET_MORE_DOCTORS_BY_SPECIALTY_SUCCESS:
            return {
                ...state,
                doctorsList: [...state.doctorsList, ...action.payload.data],
                nextPageUrl: action.payload.next_page_url
            };
        case types.GET_DOCTOR_EARNINGS:
            return {
                ...state,
                userId: action.userId,
                params: action.params
            };
        case types.GET_DOCTOR_EARNINGS_SUCCESS:
            return {
                ...state,
                earnings: action.payload
            };
        case types.GET_DOCTOR_CREDITS:
            return {
                ...state,
                doctorUserId: action.doctorUserId
            };
        case types.GET_DOCTOR_CREDITS_SUCCESS:
            return {
                ...state,
                doctorCredits: action.payload
            };
        case types.GET_DOCTOR_REVIEWS:
            return {
                ...state,
                doctorUserId: action.doctorUserId
            };
        case types.GET_DOCTOR_REVIEWS_SUCCESS:
            return {
                ...state,
                doctorReview: action.payload
            };
        case types.ADD_DOCTOR_REVIEW:
            return {
                ...state,
                isAddDoctorReviewSuccess: false,
                doctorUserId: action.doctorUserId,
                params: action.params
            };
        case types.ADD_DOCTOR_REVIEW_SUCCESS:
            return {
                ...state,
                isAddDoctorReviewSuccess: true
            };
        default:
            return state;
    }
};