import store from "../store";
import { showSnackbarAction } from "../store/actions/snackbar";
import { logoutRequest } from '../store/actions/Auth';
import messages from '../assets/Local/messages';
import { BASE_TIMEZONE } from './Constants';
import moment from 'moment-timezone';

// To show error message that returned from backend
export function dispatchSnackbarError(data) {
  if (data) {
    // const errorMsg = data.error.message;
    const errorMsg = data;
    store.dispatch(showSnackbarAction(errorMsg, "error"));
  }
}
// To show success message after any success request if needed
export function dispatchSnackbarSuccess(message) {
  // const lang = store.getState().language.lang;
  store.dispatch(
    // showSnackbarAction(messages[lang].snackbar[message], "success")
    showSnackbarAction(message)
  );
}

export function logout() {
  store.dispatch(logoutRequest());
}

export function formatPhotoUrl(photo) {
  return 'https://res.cloudinary.com/telehealth-today/image/upload/v1618429247/' + photo;
}

export const momentToLocalDateTime = (dateAndTime) => {
  let serverTimezoneDateTime = moment.tz(moment(dateAndTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'), BASE_TIMEZONE);
  return moment(serverTimezoneDateTime.format());
}


