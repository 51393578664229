import { call, put } from "redux-saga/effects";
import API from "../../network/apis/Account";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as ACTIONS from "../actions/Account";
import * as AUTH_ACTIONS from '../actions/Auth';
import * as TYPES from "../types/AccountTypes";

export function* updateAccount(action) {
    try {
        const response = yield call(API.updateAccount, action.userId, action.params);

        if (response.data.success) {
            yield put(AUTH_ACTIONS.userInfoSuccess({ ...action.currentUserInfo, user: response.data.data }));
            yield put(ACTIONS.onUpdateAccountSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updatePassword(action) {
    try {
        const response = yield call(API.updatePassword, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onUpdatePasswordSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updatePhoto(action) {
    try {
        const response = yield call(API.updatePhoto, action.userId, action.params);

        if (response.data.success) {
            yield put(AUTH_ACTIONS.userInfoSuccess({ ...action.currentUserInfo, user: response.data.data }));
            yield put(ACTIONS.onUpdatePhotoSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* requestOtp(action) {
    try {
        const response = yield call(API.requestOtp, action.userId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onRequestOtpSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* verifyOtp(action) {
    try {
        const response = yield call(API.verifyOtp, action.userId, action.params);

        if (response.data.success) {
            yield put(AUTH_ACTIONS.userInfoSuccess({ ...action.currentUserInfo, user: response.data.data.user }));
            yield put(ACTIONS.onVerifyOtpSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getTransactions(action) {
    try {
        const response = yield call(API.getTransactions, action.userId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onGetTransactionsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getMedicalHistory(action) {
    try {
        const response = yield call(API.getMedicalHistory, action.userId);

        if (response.data.success) {
            yield put(ACTIONS.onGetMedicalHistorySuccess(response.data.data.medical_history));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updateMedicalHistory(action) {
    try {
        const response = yield call(API.updateMedicalHistory, action.userId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onUpdateMedicalHistorySuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getMembershipStatus() {
    try {
        const response = yield call(API.getMembershipStatus);

        if (response.data.success) {
            yield put(ACTIONS.onGetMembershipStatusSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* accountSagas() {
    yield takeLatest(TYPES.UPDATE_ACCOUNT, updateAccount);
    yield takeLatest(TYPES.UPDATE_PASSWORD, updatePassword);
    yield takeLatest(TYPES.UPDATE_PHOTO, updatePhoto);
    yield takeLatest(TYPES.REQUEST_OTP, requestOtp);
    yield takeLatest(TYPES.VERIFY_OTP, verifyOtp);
    yield takeLatest(TYPES.GET_TRANSACTIONS, getTransactions);
    yield takeLatest(TYPES.GET_MEDICAL_HISTORY, getMedicalHistory);
    yield takeLatest(TYPES.UPDATE_MEDICAL_HISTORY, updateMedicalHistory);
    yield takeLatest(TYPES.GET_MEMBERSHIP_STATUS, getMembershipStatus);
}